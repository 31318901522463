/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import Img from 'gatsby-image/withIEPolyfill';
import AnimateOnEntrance from '../components/fx/animate/AnimateOnEntrance';
import { ScrollContainer } from '../context/scrollBehavior';

import RegisterSignUp from '../components/global/RegisterSignUp';
import SEO from '../components/SEO';
import HowCanWeHelp from '../components/layout/landingPage/HowCanWeHelp';

// determine image sourcing by env
import useAuth from '../util/hooks/useAuth';
import ExceptionalSales from '../components/ExceptionalSales';
import ImageInView from '../components/fx/animate/ImageInView';

const ExceptionalSalesPage = ({ data }) => {
  const [device, setDevice] = useState('mobile');
  useAuth();

  return (
    <>
      <SEO title="Exceptional Sales" />
      <Layout
        device={device}
        setDevice={setDevice}
        logo={data.logo}
        menu={data.allMenuMenuLink}
        footerMenu={data.footer_menu}
        locations={data.locations.edges}
        className={'artworks-main-page'}
      >
        <ScrollContainer force={true}>
          <ExceptionalSales
            artwork={data.artwork}
            device={device}
            basePath={`exceptional-sales`}
          />
        </ScrollContainer>
        <ScrollContainer force={true}>
          <section className="grid-section register-signup grid-content-container t-left set-up-account">
            <div className="t-content-container t-left">
              <div className="grid-2 grid-justify-center grid-align-center grid-gap-lg">
                <div className="grid-col form-set-up-account">
                  <div className="t-heading-container">
                    <AnimateOnEntrance className="fadeInLeft" device={device}>
                      <h3 className="t-mxlarge t-heading">
                      Become a member!
                      </h3>
                    </AnimateOnEntrance>
                    <div className="t-content-container t-left t-callout">
                      <AnimateOnEntrance className="fadeInLeft" device={device}>
                        <p>
                        As a member of My Loch Gallery you can set up a collector profile, save artworks and be notified of new work by your favourite atists, access to our newsletter, and more.
                        </p>
                      </AnimateOnEntrance>
                    </div>
                  </div>
                  <AnimateOnEntrance className="fadeInLeft" device={device}>
                    <RegisterSignUp />
                  </AnimateOnEntrance>
                </div>
                <div className="grid-col mobile-no">
                  <AnimateOnEntrance className="fadeInRight" device={device}>
                    <ImageInView src={"/media/su_grid.jpg"} />
                  </AnimateOnEntrance>
                </div>
              </div>
            </div>
          </section>
        </ScrollContainer>
        <HowCanWeHelp />
      </Layout>
    </>
  );
};

export default ExceptionalSalesPage;

export const ExceptionalSalesQuery = graphql`
  query ExceptionalSalesQuery {
    artwork: allNodeArtwork(
      filter: {
        status: { eq: true }
        relationships: {
          field_artist_ref: { status: { eq: true } }
          field_artwork_status: { name: { eq: "Sold" } }
        }
        field_exceptional_artwork_sold: { eq: 1 }
      }
    ) {
      edges {
        node {
          drupal_id
          field_art_price_original {
            currency_code
            number
          }
          created
          field_art_price_published
          field_art_year
          field_artist_payment
          field_artist_payment_amount
          field_artwork_name
          field_circa
          field_depth
          field_exceptional_artwork_sold
          field_height
          field_width
          field_dimensions_computed
          field_net_artwork_profit
          field_order_exceptional_sale
          title
          path {
            alias
          }
          field_searchable_price
          field_published_sold_artwork
          field_promoted_artwork
          field_price_avail_on_request
          field_year_or_circa
          relationships {
            field_location_ref {
              title
            }
            field_artwork_images {
              uri {
                url
              }
              image_style_uri {
                gatsby_artwork_image
                gatsby_artwork_thumbnail
                gatsby_landing_page_banner
              }
            }
            field_artist_ref {
              title
              drupal_id
              path {
                alias
              }
              relationships {
                field_artist_type {
                  name
                }
                field_artist_origin {
                  name
                }
              }
            }
            field_artwork_type {
              name
            }
            field_artwork_medium {
              name
            }
            field_artwork_status {
              name
            }
            field_artwork_subject {
              name
            }
          }
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
  }
`;
