import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import SearchIcon from '../assets/media/search-dark.svg';
import ChevronDown from '../assets/media/chevron-down-solid.svg';
import { useQuery, decodeHTML } from './helper/pagePaths';
import { useLocation } from '@reach/router';
import ArtworkFilterCard from './global/ArtworkFilterCard';

const ExceptionalSales = ({ artwork, device, basePath }) => {
  const [search, setSearch] = useState(0);
  const [page, setPage] = useState(1);
  const [type, setType] = useState(0);
  const [artworkType, setArtworkType] = useState(0);
  const [origin, setOrigin] = useState(0);

  const pageLength = 6;
  let matchingArtwork = artwork.edges;

  // filter out artworks with null artists
  matchingArtwork = matchingArtwork.filter(
    ({ node: artwork }) => artwork.relationships.field_artist_ref
  );

  // create a list of matching artists
  let matchingArtists = matchingArtwork.map(
    ({ node: artwork }) => artwork.relationships.field_artist_ref?.title
  );
  matchingArtists = [...new Set(matchingArtists)];

  // load query params on init
  const pathname = useLocation().pathname;
  const params = useQuery(basePath, pathname);

  useEffect(() => {
    const { search: usearch, type: utype, artworkType: uartworkType } = params;
    if (usearch !== search) {
      setSearch(decodeHTML(usearch));
    }
    if (utype !== type) {
      setType(decodeHTML(utype));
    }
    if (uartworkType !== artworkType) {
      setArtworkType(uartworkType);
    }
  }, [pathname]);

  // update path
  // with page, leaving off for now
  /* useEffect(() => {
        navigate(updatePath({letter, type, origin, search, page}))
    }, [letter, setLetter, type, setType, origin, setOrigin, search, setSearch, page, setPage]) */
  useEffect(() => {
    navigate(
      updatePath({
        search,
        type,
        artworkType,
        origin,
      })
    );
  }, [
    search,
    setSearch,
    type,
    setType,
    artworkType,
    setArtworkType,
    origin,
    setOrigin,
  ]);

  // update the path dynamically
  const updatePath = (pathParams) => {
    let pathParts = `/${basePath}`;
    if (type) {
      pathParts += `/type/${type}`;
    }
    if (artworkType) {
      pathParts += `/artworkType/${artworkType}`;
    }
    if (origin) {
      pathParts += `/origin/${origin}`;
    }
    if (search) {
      pathParts += `/search/${search}`;
    }
    return pathParts;
  };

  // define infinite scrolling behavior
  const infiniteScroller = useRef(null);

  const handleScroll = () => {
    if (isInViewport(infiniteScroller)) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, page, setPage]);

  const isInViewport = (el, offset = 0) => {
    if (!el.current) return false;
    const top = el.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  };

  // filter by search keyword
  if (search) {
    search.replace(' ', '.*');
    const searchRegExp = new RegExp(`.*${search}.*$`, 'i');
    matchingArtwork = matchingArtwork.filter(
      ({ node: artwork }) =>
        artwork.title.match(searchRegExp) ||
        artwork.relationships.field_artist_ref?.title.match(searchRegExp)
    );
    matchingArtists = matchingArtists.filter((artist) => {
      return artist.match(searchRegExp);
    });
  }

  const changeSearch = (e, value = false) => {
    let keyword = value;
    if (e) {
      keyword = e.target.value;
    }
    setSearch(keyword);
    setPage(1);
  };

  // filter by artist type
  // historic, contemporary
  if (type) {
    matchingArtwork = matchingArtwork.filter(
      ({ node: artwork }) =>
        artwork.relationships.field_artist_ref?.relationships.field_artist_type
          .name === type
    );
  }

  const changeType = (new_type) => {
    if (type === new_type) {
      setType(0);
    } else {
      setType(new_type);
    }
    setPage(1);
  };

  // filter by origin
  if (origin) {
    matchingArtwork = matchingArtwork.filter(
      ({ node: artwork }) =>
        artwork.relationships.field_artist_ref?.relationships
          .field_artist_origin.name === origin
    );
  }

  const changeOrigin = (new_origin) => {
    if (origin === new_origin) {
      setOrigin(0);
    } else {
      setOrigin(new_origin);
    }
    setPage(1);
  };

  // get the page results
  if (matchingArtwork.length > pageLength * page) {
    // paginate
    matchingArtwork = matchingArtwork.slice(0, pageLength * page);
  }

  return (
    <div className="artists exceptional-sales">
      <section className="section section-title">
        <div className="container">
          <div className="columns">
            <div className="column page-title">
              <h2>Search Exceptional Sales</h2>
              <p style={{ marginTop: `2rem` }}>
                Let us help you build the finest collection of exceptional
                artworks. Loch Gallery has been assisting clients since 1972 in
                finding the highest quality artworks from an array of historical
                and contemporary artists from Canada and abroad.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-filter">
        <div className="container">
          <div className="columns">
            <div className="column is-three-fifths search-container">
              <div id="artwork_filters" className="columns is-multiline">
                <div className="column">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${
                          origin || type ? 'is-active' : ''
                        }`}
                        aria-haspopup="true"
                        aria-controls="artist_type"
                      >
                        <span>Artist Type</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} alt="down chevron" />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="artist_type" role="menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <form action="">
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Contemporary
                                  <input
                                    type="checkbox"
                                    checked={
                                      type === 'Established living contemporary'
                                    }
                                    onClick={() =>
                                      changeType(
                                        'Established living contemporary'
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Historical
                                  <input
                                    type="checkbox"
                                    checked={
                                      type ===
                                      'Historical works of significance'
                                    }
                                    onClick={() =>
                                      changeType(
                                        'Historical works of significance'
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  International
                                  <input
                                    type="checkbox"
                                    checked={origin === 'International'}
                                    onClick={() =>
                                      changeOrigin('International')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field form-actions">
                              <div
                                className={`control ${
                                  origin || type ? 'is-active' : ''
                                }`}
                              >
                                <a
                                  role="button"
                                  onClick={() => {
                                    changeType(0);
                                    changeOrigin(0);
                                  }}
                                  onKeyDown={() => {
                                    changeType(0);
                                    changeOrigin(0);
                                  }}
                                >
                                  Clear
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field search-field">
                <div className="control has-icons-left">
                  <label>
                    <input
                      className="input"
                      type="text"
                      value={search ? search : ''}
                      onChange={changeSearch}
                    />
                  </label>
                  {search && matchingArtists.length > 0 ? (
                    <div className="input-suggest">
                      <ul>
                        {matchingArtists.map((artist, i) =>
                          artist !== search ? (
                            <li
                              key={`artist_${i}`}
                              role="button"
                              tabIndex={0}
                              onClick={() => changeSearch(false, artist)}
                              onKeyDown={() => changeSearch(false, artist)}
                            >
                              {artist}
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  ) : null}
                  <span className="icon is-small is-left">
                    <img src={SearchIcon} alt="search icon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="columns">
            <div className="column is-three-fifths">
              <div className="columns" id="export_description">
                <div className="column">
                  <p>
                    You can select up to 40 pieces of artwork and print them in
                    a PDF.
                  </p>
                </div>
              </div>
              <div className="columns" id="export_button">
                <div className="column">
                  <button className="button blk-button">Export as PDF</button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section className="section">
        <div id="artwork_searches" className="container">
          <div className="columns">
            {matchingArtwork.length ? (
              matchingArtwork.map(({ node: artwork }, i) => (
                <ArtworkFilterCard
                  key={artwork.drupal_id}
                  artwork={artwork}
                  device={device}
                />
              ))
            ) : (
              <div className="container">
                <h3>Sorry, we didn't find any results for that filter</h3>
              </div>
            )}
          </div>
        </div>
      </section>
      <div
        className="infinite-scroll-detection"
        ref={infiniteScroller}
        style={{ padding: '40px' }}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default ExceptionalSales;
