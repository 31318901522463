import React from 'react';
import { Link, navigate } from 'gatsby';
import ImageFromField from './ImageFromField';
import { priceFixed, artworkDimensions } from '../helper/artwork';
import ActionButtons, { FollowBtn } from './ActionButtons';
import { usePrintArtwork } from '../../util/hooks/usePrintArtwork';

const ArtworkFilterCard = ({ artwork, device, selectedList = [], addToSelectedList, onNavigateArtwork = null }) => {
  const [isPrinting, setIsPrinting] = usePrintArtwork();
  const urlBase = typeof window !== 'undefined' ? window.location.origin : '';
 
  const path = artwork.path.alias;
  const artist_path = artwork.relationships.field_artist_ref?.path.alias;
  const price_available_on_request_label = 'Price available on request';
  const artwork_price = priceFixed(artwork.field_art_price_original);
  const display_price = !Boolean(artwork.field_price_avail_on_request)
    ? artwork_price
    : price_available_on_request_label;

  const isSelected = (id) => selectedList.includes(id);
  const hasSelectedList = typeof addToSelectedList !== "undefined";

  const handleNavigation = (path, drupal_id) => {
    onNavigateArtwork(`artwork_${drupal_id}`);
    navigate(path);
  }

  return (
    <div
      className={`column is-6 ${isPrinting ? 'is-print-target' : ''}`}
      key={`artwork_${artwork.drupal_id}`}
      id={`artwork_${artwork.drupal_id}`}
    >
      <div className="field is-grouped">
        {hasSelectedList && 
        <div className="control control-checkbox">
          <label for="" className="checkbox">
            <input 
              type="checkbox" 
              name="" 
              id="" 
              checked={isSelected(artwork.drupal_internal__nid)}
            />
            <span 
              className="checkmark"
              onClick={() => addToSelectedList(artwork.drupal_internal__nid)}
            ></span>
          </label>
        </div>
        }
        
        <div className="control control-img">
          <span class="link" onClick={() => handleNavigation(path, artwork.drupal_id)} onPress={() => handleNavigation(path, artwork.drupal_id)} to={path}>
            <ImageFromField
              img={artwork.relationships.field_artwork_images[0]}
              device={device}
              fallback={'./media/logo.png'}
              imageSize="gatsby_artwork_thumbnail"
            />
          </span>
        </div>
        <div className="control artwork-data">
          <ul className="artist-info">
            <li>
              <h3 className="artist-title">
              <span class="link" onClick={() => handleNavigation(artist_path, artwork.drupal_id)} onPress={() => handleNavigation(artist_path, artwork.drupal_id)} to={path}>
                {artwork.relationships.field_artist_ref?.title}
              </span>
              </h3>
            </li>
            <li>
              <FollowBtn
                drupal_id={
                  artwork.relationships.field_artist_ref?.drupal_id
                    ? artwork.relationships.field_artist_ref?.drupal_id
                    : null
                }
              />
            </li>
          </ul>
          <ul className="piece-details">
            <li>
              <h3>{artwork.field_artwork_name}</h3>
            </li>
            <li>
              {artwork.relationships.field_artwork_medium &&
              artwork.relationships.field_artwork_medium.length > 0
                ? artwork.relationships.field_artwork_medium
                    .map((medium) => {
                      return medium.name;
                    })
                    .join(', ')
                : null}
            </li>
            <li>{artworkDimensions(artwork)}</li>
            <li>{artwork.field_year_or_circa}</li>
            {artwork?.relationships?.field_artwork_status?.name ===
              'Sold' || artwork?.relationships?.field_artwork_status?.data?.id === "3e499e4e-8f77-42d9-b645-7129f448bc93" || 
              artwork?.status === false ? (
              <li className="label-sold">Sold</li>
            ) : (
              <li>{display_price}</li>
            )}
            <li>{artwork.field_artwork_status}</li>
          </ul>
          <ActionButtons
            showText={false}
            drupal_id={artwork.drupal_id}
            url={`${urlBase}${artwork.path.alias}`}
            title={artwork.field_artwork_name}
            artist_title={artwork.relationships.field_artist_ref?.title}
            location={artwork.relationships?.field_location_ref?.title}
            showToolTips={true}
            centerToolTips={true}
            setIsPrinting={setIsPrinting}
          />
        </div>
      </div>
    </div>
  );
};

export default ArtworkFilterCard;
